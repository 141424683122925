import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import balanceImage from '../../images/balance.avif';
import signatureImage from '../../images/signature.avif';
import { CButton } from '../Styles/styles';

const SectionTen = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f1f7f3',
          marginTop: '0px',
          padding: '32px 16px',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={4.5}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#e7f1ea',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={balanceImage}
                  alt="balanceImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#2a2924',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                      marginBottom: '38px',
                    }}
                  >
                    Fuel your growth with startup-friendly Venture Debt
                  </Typography>
                </Box>
                <CButton variant="outlined">Grow with Venture Debt</CButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#e7f1ea',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={signatureImage}
                  alt="signatureImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#2a2924',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                      marginBottom: '38px',
                    }}
                  >
                    Speed up your fundraise with free SAFEs
                  </Typography>
                </Box>
                <CButton variant="outlined">Create a SAFE</CButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionTen;
