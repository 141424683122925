export const invoicePoints = [
  'Hold your money for longer by eliminating third-party processing',
  'Harness AI to populate bill details for you',
  'Set multi-layered approvals and approve payments instantly via Slack',
  'Never overpay with duplicate bill detection',
  'Effortlessly build and track invoices (coming soon)',
];

export const spendPoints = [
  'Manage card spend with flexible limits',
  'Tailor permissions to each team member',
  'Lock cards to specific merchants',
  'Easily spot duplicate subscriptions',
  'Implement reimbursement policies in minutes (coming soon)',
];

export const bookPoints = [
  'Sync all your expenses to QuickBooks, NetSuite, or Xero',
  'Create rules to code card transactions',
  'See all your bills, cards, and bank transactions in one place',
  'Send automated receipt nudges and easily review transactions',
];

export const content = [
  {
    title: 'Banking',
    items: [
      'Business Checking & Savings',
      'Treasury',
      'Corporate Credit Cards',
      'Venture Debt',
      'Personal Banking',
    ],
  },
  {
    title: 'Platform',
    items: ['Pricing', 'Security', 'Vault', 'API', 'Perks'],
  },
  {
    title: 'Resources',
    items: ['Help Center', 'Meridian', 'The Bottom Line', 'Events'],
  },
  {
    title: 'Finance Ops',
    items: [
      'Financial Workflows',
      'Bill Pay',
      'Accounting Automations',
      'SAFEs',
    ],
  },
  {
    title: 'Industries',
    items: ['SaaS', 'Ecommerce', 'VC Funds', 'Life Science', 'Web3'],
  },
  {
    title: 'Expense Raise',
    items: [
      'Raise Overview',
      'Investor Database',
      'Investor Connect',
      'Founder Community',
      'Software Stack',
    ],
  },
  {
    title: 'Account',
    items: ['Open Account', 'Log In', 'iOS', 'Android'],
  },
  {
    title: 'About',
    items: [
      'How Expense Works',
      'Our Story',
      'Careers',
      'Partnerships',
      'Contact',
      'Legal',
      'Privacy Policy',
    ],
  },
  {
    title: 'Follow Us',
    items: ['X', 'LinkedIn', 'YouTube', 'Instagram'],
  },
];

export const disclaimer = [
  'Expense is a fintech company, not an FDIC-insured bank. Banking services provided by Choice Financial Group and Evolve Bank & Trust ®, Members FDIC. Deposit insurance covers the failure of an insured bank.',
  'Expense is a fintech company, not an FDIC-insured bank. Banking services provided by Choice Financial Group and Evolve Bank & Trust ®, Members FDIC. Deposit insurance covers the failure of an insured bank.',
  'You must have an account with Expense and meet deposit minimums to become eligible for IO.',
  'To receive cashback, your Expense accounts must be open and in good standing, meaning they cannot be suspended, restricted, past due, or otherwise in default. Learn more about cashback.',
  'Expense is a fintech company, not an FDIC-insured bank. Deposits in checking and savings accounts are held by our banking services partners, Choice Financial Group and Evolve Bank & Trust ®, Members FDIC. Deposit insurance covers the failure of an insured bank. Certain conditions must be satisfied for pass-through insurance to apply. Learn more here.',
  'Expense Treasury, by Expense Advisory, LLC, an SEC-registered investment adviser, seeks to earn net returns up to 5.38% annually on your idle cash. Net yield numbers as of 06/10/2024, and assumes total Expense deposits of $20M+.',
  'The performance discussed herein is historic and reflects an investment for a limited period of time. It should not be assumed that future investors would experience returns, if any, comparable to those illustrated herein. Past performance is not indicative of future returns. Investment results will fluctuate. Returns are not guaranteed. All investments are subject to the risk of loss, including the loss of principal. No representation is being made that an investment account has, will, or is likely to achieve profits or losses equal to the profits or losses shown. Actual returns will vary greatly and depend on personal and market conditions. Before investing, consider your financial goals and the costs of using the program.',
  'Furthermore, the information set forth has been obtained from sources that the Firm believes to be reliable; however, these sources cannot be guaranteed as to their accuracy or completeness. The information contained herein is not, and should not be construed as, an offer to sell or the solicitation of an offer to buy any securities. The information has been prepared solely for the purpose of determining your level of interest in Expense Treasury and to provide general background information on such algorithmic investment program.',
  'This information contains certain “forward-looking statements,” which may be identified by the use of such words as “believe,” “expect,” “anticipate,” “should,” “planned,” “estimated,” “potential” and other similar terms.',
  'Examples of forward-looking statements include, but are not limited to, estimates with respect to financial condition, results of operations, and success or lack of success of the depicted investment strategy. All are subject to various factors, including, but not limited to general and local economic conditions, changing levels of competition within certain industries and markets, changes in interest rates, changes in legislation or regulation, and other economic, competitive, governmental, regulatory and technological factors affecting operations that could cause actual results to differ materially from projected results.',
  'Targeted returns (e.g., forward-looking statements of performance up to a stated return) reflects the returns that the Firm is seeking to achieve over a particular period of time. Projected returns reflect the Firm’s performance estimate – i.e., the returns that the Firm believes can be achieved using the advertised investment services. Target returns are presented to inform clients or potential clients about the Firm’s risk tolerances when managing the Expense Treasury strategy and to provide information useful to a client or potential client when assessing how the Firm’s strategy fits within the investor’s overall portfolio. Target returns are not guarantees or promises of future return.',
  'Expense Treasury is not insured by the FDIC. Expense Treasury are not deposits or other obligations of Choice Financial Group or Evolve Bank & Trust, and are not guaranteed by Choice Financial Group or Evolve Bank & Trust. Expense Treasury products are subject to investment risks, including possible loss of the principal invested',
];

export const productsMenu1 = [
  {
    title: 'Banking',
    items: [
      'Business Checking & Savings',
      'Treasury',
      'Corporate Credit Cards',
      'Venture Debt',
      'Personal Banking',
    ],
  },
  {
    title: 'Platform',
    items: ['Pricing', 'Security', 'Vault', 'API', 'Perks'],
  },
];

export const productsMenu2 = [
  {
    title: 'Finance Ops',
    items: [
      'Financial Workflows',
      'Bill Pay',
      'Accounting Automations',
      'SAFEs',
    ],
  },
  {
    title: 'Apps',
    items: ['iOS App', 'Android App'],
  },
];

export const resourcesMenu = [
  {
    link: 'Expense Raise',
    title: 'Connecting startups with capital, mentorship, and each other.',
  },
  {
    link: 'Meridian',
    title:
      'A magazine dedicated to the world of tech and the humans that make it.',
  },
  {
    link: 'The Bottom Line',
    title: 'Advice, guides, and inspiration for startups.',
  },
];

export const aboutMenu = [
  'How Expense Works',
  'Our Story',
  'Careers',
  'Partnerships',
  'Contact',
];

export const sidebar = [
  'Products',
  'Resources',
  'About',
  'Pricing',
];

export const productsSideBar = [
  {
    title: 'Banking',
    items: [
      'Business Checking & Savings',
      'Treasury',
      'Corporate Credit Cards',
      'Venture Debt',
      'Personal Banking',
    ],
  },
  {
    title: 'Platform',
    items: ['Pricing', 'Security', 'Vault', 'API', 'Perks'],
  },
  {
    title: 'Finance Ops',
    items: [
      'Financial Workflows',
      'Bill Pay',
      'Accounting Automations',
      'SAFEs',
    ],
  },
  {
    title: 'Apps',
    items: ['iOS App', 'Android App'],
  },
];
