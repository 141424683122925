import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { disclaimer } from '../../constants';
import Box from '@mui/material/Box';

const SectionFourteen = () => {
  return (
    <Grid
      style={{
        backgroundColor: '#181623',
        marginTop: '0px',
        padding: '32px 16px',
      }}
    >
      <Grid container spacing={2} style={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={3}>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 450,
              lineHeight: '140%',
              letterSpacing: 0.48,
              color: '#f5f4fd',
            }}
          >
            Disclaimers and footnotes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {disclaimer.map((item, index) => (
            <Box key={index}>
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 450,
                  lineHeight: '140%',
                  letterSpacing: 0.48,
                  color: '#f5f4fd',
                  marginBottom: '16px',
                }}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionFourteen;
