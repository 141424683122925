import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import booksPoster from '../../images/booksPoster.webp';
import booksVideo from '../../videos/books.mp4';
import VideoPlayer from '../Common/VideoPlayer';
import { bookPoints } from '../../constants';
import {
  ArrowTextBox,
  IconArrowForwardRounded,
  TypographySixArrowText,
  FitContentButton,
} from '../Styles/styles';

const SectionEight = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#eef7fa',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#0f2d37',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginBottom: '24px',
              }}
            >
              Close the books quickly and accurately
            </Typography>
            <FitContentButton variant="outlined">
              Explore Accounting Automations
            </FitContentButton>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            {bookPoints.map((item, index) => (
              <ArrowTextBox key={index}>
                <IconArrowForwardRounded />
                <TypographySixArrowText>{item}</TypographySixArrowText>
              </ArrowTextBox>
            ))}
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <VideoPlayer videoSrc={booksVideo} posterSrc={booksPoster} />
            {/* <Box>
              <img
                src={lightGreenImage}
                alt="lightGreenImage"
                loading="lazy"
                style={{
                  width: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionEight;
