import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
  ArrowTextBox,
  IconArrowForwardRounded,
  TypographySixArrowText,
  FitContentButton,
} from '../Styles/styles';
import { invoicePoints } from '../../constants';
import invoicePoster from '../../images/invoicePoster.webp';
import invoiceVideo from '../../videos/invoice.mp4';
import VideoPlayer from '../Common/VideoPlayer';

const SectionSix = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#eef7fa',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid
          container
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant="h2"
            style={{
              color: '#0f2d37',
              fontSize: '36px',
              fontWeight: 450,
              lineHeight: '110%',
              letterSpacing: 0,
              textAlign: 'center',
            }}
          >
            All your financial workflows. Zero complexity.
          </Typography>
          <Typography
            style={{
              color: '#335c6b',
              fontSize: '24px',
              fontWeight: 450,
              lineHeight: '120%',
              letterSpacing: '0.08px',
              marginTop: '8px',
              marginBottom: '32px',
              textAlign: 'center',
            }}
          >
            When your bank account powers your critical financial workflows,
            you’re already in the flow.
          </Typography>
        </Grid>
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#0f2d37',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginBottom: '24px',
              }}
            >
              Handle all your bills and invoices with precision
            </Typography>
            <FitContentButton variant="outlined">
              Explore Bill Pay
            </FitContentButton>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            {invoicePoints.map((item, index) => (
              <ArrowTextBox key={index}>
                <IconArrowForwardRounded />
                <TypographySixArrowText>{item}</TypographySixArrowText>
              </ArrowTextBox>
            ))}
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <VideoPlayer videoSrc={invoiceVideo} posterSrc={invoicePoster} />
            {/* <Box>
              <img
                src={lightGreenImage}
                alt="lightGreenImage"
                loading="lazy"
                style={{
                  width: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionSix;
