import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FitContentButton } from '../Styles/styles';
import expertPoster from '../../images/expertPoster.webp';
import expertVideo from '../../videos/expert.mp4';
import VideoPlayer from '../Common/VideoPlayer';

const SectionEleven = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f6f5f2',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid
          container
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant="h2"
            style={{
              color: '#0f2d37',
              fontSize: '36px',
              fontWeight: 450,
              lineHeight: '110%',
              letterSpacing: 0,
              textAlign: 'center',
            }}
          >
            Tap into industry expertise
          </Typography>
          <Typography
            style={{
              color: '#335c6b',
              fontSize: '24px',
              fontWeight: 450,
              lineHeight: '120%',
              letterSpacing: '0.08px',
              marginTop: '8px',
              marginBottom: '32px',
              textAlign: 'center',
            }}
          >
            Get the network and knowledge you need to navigate the obstacles of
            company building.
          </Typography>
        </Grid>
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={4.5}>
            <VideoPlayer videoSrc={expertVideo} posterSrc={expertPoster} />
            {/* <Box>
              <img
                src={lightGreenImage}
                alt="lightGreenImage"
                loading="lazy"
                style={{
                  width: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#2a2924',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginBottom: '24px',
              }}
            >
              Connect with investors, founders, and experts via our startup
              success platform
            </Typography>
            <FitContentButton variant="outlined">
              Explore Expense Raise
            </FitContentButton>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            <Typography
              style={{
                color: '#5a5548',
                fontSize: '18px',
                fontWeight: 450,
                lineHeight: '135%',
                letterSpacing: '0.32px',
                marginBottom: '32px',
              }}
            >
              I immediately felt welcomed into the founder community. I’ve
              connected with and exchanged advice with other founders and
              learned about achieving PMF from AMAs. I highly recommend it.
            </Typography>
            <Typography
              style={{
                color: '#2a2924',
                fontSize: '18px',
                fontWeight: 530,
                lineHeight: '135%',
                letterSpacing: '0.32px',
              }}
            >
              Charles Meyer
            </Typography>
            <Typography
              style={{
                color: '#5a5548',
                fontSize: '18px',
                fontWeight: 450,
                lineHeight: '135%',
                letterSpacing: '0.32px',
              }}
            >
              Founder, My Better AI
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionEleven;
