import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

export const ArrowTextBox = styled(Box)`
  display: flex;
`;

export const IconArrowForwardRounded = styled(ArrowForwardRoundedIcon)`
  color: #0f2d37;
  margin-right: 4px;
`;

export const TypographySixArrowText = styled(Typography)`
  color: #0f2d37;
  font-size: 18px;
  font-weight: 450;
  line-height: 125%;
  letter-spacing: 0.32px;
  margin-bottom: 8px;
`;

export const CButton = styled(Button)`
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  color: #28253B;
  line-height: 1;
  letter-spacing: 0.5px;
  background-color: #7073931A;
  border-radius: 24px;
  padding: 12px 18px;
  border-color: rgba(112,115,147,.22);
  width: fit-content;
`;

export const FitContentButton = styled(CButton)`
  width: fit-content;
`;
