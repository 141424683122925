import React from 'react';
import Box from '@mui/material/Box';
import partnersImage from '../../images/partners.avif';

const RegulatedPartnersTab = () => {
  return (
    <Box>
      <img
        src={partnersImage}
        alt="partnersImage"
        loading="lazy"
        style={{
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export default RegulatedPartnersTab;
