import React from 'react';
import Box from '@mui/material/Box';
import FDICImage from '../../images/FDIC.avif';

const FDICInsuranceTab = () => {
  return (
    <Box>
      <img
        src={FDICImage}
        alt="FDICImage"
        loading="lazy"
        style={{
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export default FDICInsuranceTab;
