import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabPanel, { a11yProps } from '../Common/TabPanel';
import FDICInsuranceTab from '../Tabs/FDICInsuranceTab';
import RegulatedPartnersTab from '../Tabs/RegulatedPartnersTab';
import FraudMonitoringTab from '../Tabs/FraudMonitoringTab';
import AccountSecurityTab from '../Tabs/AccountSecurityTab';
import { CButton } from '../Styles/styles';

const SectionFive = () => {
  const isMobile640 = useMediaQuery('(max-width:640px)');
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyle = (index) => ({
    color: value === index ? '#1f1d2d' : '#554e7b',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 530,
    lineHeight: 1,
    letterSpacing: 0.32,
    '&.Mui-selected': {
      color: '#1f1d2d',
    },
  });

  return (
    <Grid
      style={{
        backgroundColor: '#f5f4fd',
        marginTop: '0px',
        padding: '32px 16px',
      }}
    >
      <Grid container style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h2"
          style={{
            color: '#28253b',
            fontSize: '36px',
            fontWeight: 450,
            lineHeight: '110%',
            letterSpacing: 0,
            marginBottom: '32px',
            textAlign: 'center',
          }}
        >
          Bank with complete confidence
        </Typography>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="bankTabs"
          style={{ width: isMobile640 ? '100%' : 'auto' }}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#1f1d2d',
            },
          }}
        >
          <Tab label="FDIC insurance" {...a11yProps(0)} sx={tabStyle(0)} />
          <Tab label="Regulated partners" {...a11yProps(1)} sx={tabStyle(1)} />
          <Tab label="Fraud monitoring" {...a11yProps(2)} sx={tabStyle(2)} />
          <Tab label="Account security" {...a11yProps(3)} sx={tabStyle(3)} />
        </Tabs>
      </Grid>
      <Grid container style={{ justifyContent: 'center', marginTop: '40px' }}>
        <Grid container xs={12} sm={9}>
          <TabPanel value={value} index={0}>
            <FDICInsuranceTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RegulatedPartnersTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FraudMonitoringTab />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AccountSecurityTab />
          </TabPanel>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '32px',
        }}
      >
        <Typography
          style={{
            color: '#335c6b',
            fontSize: '24px',
            fontWeight: 450,
            lineHeight: '120%',
            letterSpacing: '0.08px',
            marginTop: '8px',
            marginBottom: '24px',
            textAlign: 'center',
          }}
        >
          Get up to 20x the industry standard<sup>4</sup> in FDIC insurance
          through our partner banks and sweep networks.
        </Typography>
        <CButton variant="outlined">How Expense Works</CButton>
      </Grid>
    </Grid>
  );
};

export default SectionFive;
