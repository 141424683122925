import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const Login = () => {
  const navigate = useNavigate();
  const isMobile640 = useMediaQuery('(max-width:640px)');

  const [showPassword, setShowPassword] = useState(false);
  console.log('showPassword', showPassword)

  const handleShowPassword = () => {
    setShowPassword((prveState) => !prveState);
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  const handleDashboard = () => {
    navigate('/');
  };

  const FormContent = () => (
    <>
      <Typography
        style={{
          fontSize: '19px',
          fontWeight: 500,
          lineHeight: '28px',
          letterSpacing: 0.2,
          color: '#1e1e2a',
          marginBottom: '32px',
        }}
      >
        Log In
      </Typography>
      <Box style={{ marginBottom: '16px' }}>
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: 0.3,
            color: '#535461',
            marginBottom: '8px',
          }}
        >
          Email
        </Typography>
        <OutlinedInput
          style={{
            border: '1px solid rgba(112, 115, 147, .1607843137254902)',
            borderRadius: '8px',
          }}
          fullWidth
          size="small"
        />
      </Box>
      <Box style={{ marginBottom: '16px' }}>
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: 0.3,
            color: '#535461',
            marginBottom: '8px',
          }}
        >
          Password
        </Typography>
        <OutlinedInput
        type={showPassword ? 'text' : 'password'}
          style={{
            border: '1px solid rgba(112, 115, 147, .1607843137254902)',
            borderRadius: '8px',
          }}
          fullWidth
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      <Typography
        style={{
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: 0.2,
          color: '#70707d',
          marginTop: '-8px',
          marginBottom: '4px',
        }}
      >
        Forgot password?
      </Typography>
      <Button
        variant="contained"
        style={{
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 500,
          color: '#FFFFFF',
          lineHeight: '1',
          letterSpacing: '0.5px',
          backgroundColor: '#5266eb',
          borderRadius: '24px',
          padding: '12px 18px',
          marginTop: '16px',
        }}
      >
        Log In
      </Button>
    </>
  );

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid
        container
        style={{
          padding: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#e7e9f4',
        }}
      >
        <Typography
        onClick={handleDashboard}
          style={{
            color: 'black',
            letterSpacing: '4px',
            fontSize: '18px',
            cursor: 'pointer'
          }}
        >
          EXPENSE
        </Typography>
        <Button
          variant="text"
          onClick={handleSignup}
          endIcon={<KeyboardArrowRightIcon />}
          style={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 500,
            color: '#363644',
            lineHeight: '1',
            letterSpacing: '0.5px',
            marginRight: '16px',
            backgroundColor: 'inherit',
            padding: '12px 18px',
          }}
        >
          Open Account
        </Button>
      </Grid>
      <Grid
        container
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: isMobile640 ? 'flex-start' : 'center',
          backgroundColor: '#e7e9f4',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: isMobile640 ? 'block ' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isMobile640 ? (
            <Box style={{ padding: '0px 20px 0px 20px' }}>
              <FormContent />
            </Box>
          ) : (
            <Card
              style={{
                width: '400px',
                borderRadius: '12px',
                padding: '40px',
                backgroundColor: '#f8f8ff',
                boxShadow:
                  '0 2px 4px 0 rgba(4, 4, 52, .06), 0 0 2px 0 rgba(91, 95, 107, .24)',
              }}
            >
              <FormContent />
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
