import React from 'react';
import Appbar from '../Appbar/Appbar';
import SectionOne from '../Sections/SectionOne';
import SectionTwo from '../Sections/SectionTwo';
import SectionThree from '../Sections/SectionThree';
import SectionFour from '../Sections/SectionFour';
import SectionFive from '../Sections/SectionFive';
import SectionSix from '../Sections/SectionSix';
import SectionSeven from '../Sections/SectionSeven';
import SectionEight from '../Sections/SectionEight';
import SectionNine from '../Sections/SectionNine';
import SectionTen from '../Sections/SectionTen';
import SectionEleven from '../Sections/SectionEleven';
import SectionTwelve from '../Sections/SectionTwelve';
import SectionThirteen from '../Sections/SectionThirteen';
import SectionFourteen from '../Sections/SectionFourteen';

const Landing = () => {
  return (
    <>
      <Appbar />
      <SectionOne />
      {/*  <SectionTwo />*/}
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionTen />
      <SectionEleven />
      <SectionTwelve />
      <SectionThirteen />
      <SectionFourteen />
    </>
  );
};

export default Landing;
