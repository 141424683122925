import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import currentPortfolioImage from '../../images/currentPortfolio.avif';
import { CButton } from '../Styles/styles';

const SectionNine = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f1f7f3',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid
          container
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant="h2"
            style={{
              color: '#2a2924',
              fontSize: '36px',
              fontWeight: 450,
              lineHeight: '110%',
              letterSpacing: 0,
              textAlign: 'center',
            }}
          >
            Your gateway to a longer runway
          </Typography>
          <Typography
            style={{
              color: '#405d4c',
              fontSize: '24px',
              fontWeight: 450,
              lineHeight: '120%',
              letterSpacing: '0.08px',
              marginTop: '8px',
              marginBottom: '32px',
              textAlign: 'center',
            }}
          >
            Accelerate your growth with Mercury Treasury<sup>5</sup> and
            financing options integrated directly with your account.
          </Typography>
        </Grid>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid
            container
            xs={12}
            sm={9}
            style={{
              backgroundColor: '#e7f1ea',
              width: '100%',
              borderRadius: '16px',
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '24px',
              }}
            >
              <Typography
                variant="h2"
                style={{
                  color: '#2a2924',
                  fontSize: '24px',
                  fontWeight: 450,
                  lineHeight: '120%',
                  letterSpacing: '0.08px',
                  marginBottom: '38px',
                }}
              >
                Earn upto 5.38% yield<sub>5</sub> on your idle cash with
                portfolios powered by Vanguard and Morgan Stanley
              </Typography>
              <CButton variant="outlined">Grow with Venture Debt</CButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '24px',
              }}
            >
              <Box>
                <img
                  src={currentPortfolioImage}
                  alt="currentPortfolioImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionNine;
