import React from 'react';
import Box from '@mui/material/Box';
import fraudImage from '../../images/fraud.avif';

const FraudMonitoringTab = () => {
  return (
    <Box>
      <img
        src={fraudImage}
        alt="fraudImage"
        loading="lazy"
        style={{
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export default FraudMonitoringTab;
