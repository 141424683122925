import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import cardVideoPoster from '../../images/cardVideoPoster.webp';
import cardVideo from '../../videos/cardVideo.mp4';
import {
  ArrowTextBox,
  IconArrowForwardRounded,
  TypographySixArrowText,
} from '../Styles/styles';
import { spendPoints } from '../../constants';
import VideoPlayer from '../Common/VideoPlayer';

const SectionSeven = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#eef7fa',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid
          container
          spacing={4}
          style={{ justifyContent: 'center' }}
        >
          <Grid item xs={12} sm={4.5}>
            <VideoPlayer videoSrc={cardVideo} posterSrc={cardVideoPoster} />
            {/* <Box style={{ position: 'absolute' }}>
              <img
                src={lightGreenImage}
                alt="lightGreenImage"
                loading="lazy"
                style={{
                  width: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#0f2d37',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginBottom: '24px',
              }}
            >
              Control spend effortlessly at any size
            </Typography>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            {spendPoints.map((item, index) => (
              <ArrowTextBox key={index}>
                <IconArrowForwardRounded />
                <TypographySixArrowText>{item}</TypographySixArrowText>
              </ArrowTextBox>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionSeven;
