import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { content } from '../../constants';
import Link from '@mui/material/Link';
import { List } from '@mui/material';

const SectionThirteen = () => {
  return (
    <Grid
      style={{
        backgroundColor: '#181623',
        marginTop: '0px',
        padding: '32px 16px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={4} xs={12} sm={9}>
        {content.map((data, index) => (
          <Grid item xs={6} sm={4} key={index}>
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 450,
                lineHeight: '140%',
                letterSpacing: 0.48,
                color: '#f5f4fd',
                marginBottom: '4px'
              }}
            >
              {data.title}
            </Typography>
            {data.items.map((item, subIndex) => (
              <List
                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                key={subIndex}
              >
                <Link
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 1,
                    letterSpacing: 0.32,
                    color: '#f5f4fd',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                      color: '#c3c0df'
                    },
                  }}
                  href="#"
                >
                  {item}
                </Link>
              </List>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SectionThirteen;
