import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import {
  productsMenu1,
  productsMenu2,
  resourcesMenu,
  aboutMenu,
  sidebar,
  productsSideBar,
} from '../../constants';
import Divider from '@mui/material/Divider';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { CButton } from '../Styles/styles';

function Appbar() {
  const navigate = useNavigate();
  const isWeb1111 = useMediaQuery('(min-width:1111px)');
  const isMobile640 = useMediaQuery('(max-width:640px)');

  const [isMainMenu, setIsMainMenu] = useState(false);
  const [mainMenuName, setMainMenuName] = useState(null);
  const [isSidebar, setIsSidebar] = useState(false);
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);
  const [anchorElAbout, setAnchorElAbout] = useState(null);
  const openProducts = Boolean(anchorElProducts);
  const openResources = Boolean(anchorElResources);
  const openAbout = Boolean(anchorElAbout);

  const handleSidebar = () => {
    setIsSidebar((prveState) => !prveState);
  };

  const handleCloseSidebar = () => {
    setIsSidebar((prveState) => !prveState);
  };

  const handleSidebarData = (item) => {
    setMainMenuName(item);
    setIsMainMenu(true);
  };

  const handleCloseMainMenu = () => {
    setIsMainMenu((prveState) => !prveState);
  };

  const handleProductsMenu = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleResourcesMenu = (event) => {
    setAnchorElResources(event.currentTarget);
  };

  const handleAboutMenu = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleCloseProductsMenu = () => {
    setAnchorElProducts(null);
  };

  const handleCloseResourcesMenu = () => {
    setAnchorElResources(null);
  };

  const handleCloseAboutMenu = () => {
    setAnchorElAbout(null);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  const DrawerList = (
    <Box
      role="presentation"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
      }}
    >
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '8px',
          }}
        >
          <IconButton
            size="large"
            onClick={handleCloseSidebar}
            style={{ color: '272735', marginRight: '24px' }}
          >
            <CloseRoundedIcon fontSize="large" />
          </IconButton>
        </Box>
        <List style={{ padding: '0px 16px' }}>
          {sidebar.map((item, index) => (
            <>
              <Divider />
              <ListItem
                key={index}
                disablePadding
                onClick={() => handleSidebarData(item)}
              >
                <ListItemButton>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </Box>
      <Box style={{ padding: '0px 0px 32px 16px' }}>
        <Button
          variant="contained"
          onClick={handleSignup}
          style={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            lineHeight: '1',
            letterSpacing: '0.5px',
            marginRight: '16px',
            backgroundColor: '#5266eb',
            borderRadius: '24px',
            padding: '12px 18px',
          }}
        >
          Open Account
        </Button>
        <CButton variant="outlined" onClick={handleLogin}>
          Log In
        </CButton>
      </Box>
    </Box>
  );

  const mainMenuDrawer = (
    <Box role="presentation">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '8px',
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIosNewRoundedIcon />}
          onClick={handleCloseMainMenu}
          style={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 500,
            color: '#000000',
            lineHeight: '1',
            letterSpacing: '0.5px',
            marginRight: '8px',
          }}
        >
          Main menu
        </Button>
        <IconButton
          size="large"
          onClick={handleCloseMainMenu}
          style={{ color: '272735', marginRight: '24px' }}
        >
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </Box>
      {mainMenuName === 'Products' && (
        <Grid container spacing={2} style={{ padding: '16px' }}>
          {productsSideBar.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 450,
                  lineHeight: '140%',
                  letterSpacing: 0.48,
                  color: '#535461',
                  marginBottom: '4px',
                }}
              >
                {data.title}
              </Typography>
              {data.items.map((item, subIndex) => (
                <List
                  style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                  key={subIndex}
                >
                  <Link
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: 1,
                      letterSpacing: 0.32,
                      color: '#272735',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: '#535461',
                      },
                    }}
                    href="#"
                  >
                    {item}
                  </Link>
                </List>
              ))}
            </Grid>
          ))}
        </Grid>
      )}
      {mainMenuName === 'Resources' && (
        <Grid
          container
          spacing={2}
          style={{ padding: '16px', flexDirection: 'column' }}
        >
          {resourcesMenu.map((data, index) => (
            <>
              <List
                key={index}
                style={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '16px',
                }}
              >
                <Link
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 1,
                    letterSpacing: 0.32,
                    color: '#272735',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                      color: '#535461',
                    },
                  }}
                  href="#"
                >
                  {data.link}
                </Link>
              </List>
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 450,
                  lineHeight: '140%',
                  letterSpacing: 0.48,
                  color: '#535461',
                  marginBottom: '4px',
                  paddingLeft: '16px',
                }}
              >
                {data.title}
              </Typography>
            </>
          ))}
        </Grid>
      )}
      {mainMenuName === 'About' && (
        <Grid
          container
          spacing={2}
          style={{ padding: '16px', flexDirection: 'column' }}
        >
          {aboutMenu.map((data, index) => (
            <List
              key={index}
              style={{
                paddingTop: '8px',
                paddingLeft: '16px',
              }}
            >
              <Link
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: 1,
                  letterSpacing: 0.32,
                  color: '#272735',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: '#535461',
                  },
                }}
                href="#"
              >
                {data}
              </Link>
            </List>
          ))}
        </Grid>
      )}
    </Box>
  );

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: 'lavender', boxShadow: 'none' }}
      >
        <Toolbar>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: 'center',
              justifyContent: isWeb1111 ? 'unset' : 'space-between',
            }}
          >
            <Grid item xs={6} sm={isWeb1111 ? 4 : 6}>
              <Typography
                style={{
                  color: 'black',
                  letterSpacing: '4px',
                  fontSize: '18px',
                }}
              >
                EXPENSE
              </Typography>
            </Grid>
            {isWeb1111 && (
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Button
                  id="basic-button"
                  aria-controls={openProducts ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProducts ? 'true' : undefined}
                  variant="text"
                  onClick={handleProductsMenu}
                  endIcon={
                    openProducts ? (
                      <KeyboardArrowUpRoundedIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  style={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#000000',
                    lineHeight: '1',
                    letterSpacing: '0.5px',
                  }}
                >
                  Products
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElProducts}
                  open={openProducts}
                  onClose={handleCloseProductsMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '32px',
                      borderRadius: '16px',
                      backgroundColor: '#fbfcfd',
                      marginLeft: '-80px',
                    },
                  }}
                >
                  <Box
                    style={{ width: '712px', height: '420px', padding: '16px' }}
                  >
                    <Grid container spacing={2} style={{ padding: '16px' }}>
                      {productsMenu1.map((data, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: 450,
                              lineHeight: '140%',
                              letterSpacing: 0.48,
                              color: '#535461',
                              marginBottom: '4px',
                            }}
                          >
                            {data.title}
                          </Typography>
                          {data.items.map((item, subIndex) => (
                            <List
                              style={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                              }}
                              key={subIndex}
                            >
                              <Link
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  lineHeight: 1,
                                  letterSpacing: 0.32,
                                  color: '#272735',
                                  textDecoration: 'none',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    color: '#535461',
                                  },
                                }}
                                href="#"
                              >
                                {item}
                              </Link>
                            </List>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ padding: ' 16px 32px 24px 16px' }}
                    >
                      {productsMenu2.map((data, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: 450,
                              lineHeight: '140%',
                              letterSpacing: 0.48,
                              color: '#535461',
                              marginBottom: '4px',
                            }}
                          >
                            {data.title}
                          </Typography>
                          {data.items.map((item, subIndex) => (
                            <List
                              style={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                              }}
                              key={subIndex}
                            >
                              <Link
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  lineHeight: 1,
                                  letterSpacing: 0.32,
                                  color: '#272735',
                                  textDecoration: 'none',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    color: '#535461',
                                  },
                                }}
                                href="#"
                              >
                                {item}
                              </Link>
                            </List>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                    <Divider />
                    <List
                      style={{
                        paddingTop: '24px',
                        paddingBottom: '4px',
                        paddingLeft: '16px',
                      }}
                    >
                      <Link
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: 1,
                          letterSpacing: 0.32,
                          color: '#272735',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: '#535461',
                          },
                        }}
                        href="#"
                      >
                        Explore Demo
                      </Link>
                    </List>
                  </Box>
                </Menu>
                <Button
                  id="basic-button"
                  aria-controls={openResources ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openResources ? 'true' : undefined}
                  variant="text"
                  onClick={handleResourcesMenu}
                  endIcon={
                    openResources ? (
                      <KeyboardArrowUpRoundedIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  style={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#000000',
                    lineHeight: '1',
                    letterSpacing: '0.5px',
                  }}
                >
                  Resources
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElResources}
                  open={openResources}
                  onClose={handleCloseResourcesMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '32px',
                      borderRadius: '16px',
                      backgroundColor: '#fbfcfd',
                      marginLeft: '-80px',
                    },
                  }}
                >
                  <Box
                    style={{ width: '372px', height: '331px', padding: '16px' }}
                  >
                    <Grid container spacing={2} style={{ padding: '16px' }}>
                      {resourcesMenu.map((data, index) => (
                        <>
                          <List
                            key={index}
                            style={{
                              paddingTop: '8px',
                              paddingBottom: '8px',
                              paddingLeft: '16px',
                            }}
                          >
                            <Link
                              sx={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: 1,
                                letterSpacing: 0.32,
                                color: '#272735',
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                  color: '#535461',
                                },
                              }}
                              href="#"
                            >
                              {data.link}
                            </Link>
                          </List>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: 450,
                              lineHeight: '140%',
                              letterSpacing: 0.48,
                              color: '#535461',
                              marginBottom: '4px',
                              paddingLeft: '16px',
                            }}
                          >
                            {data.title}
                          </Typography>
                        </>
                      ))}
                    </Grid>
                    <Divider />
                    <List
                      style={{
                        paddingTop: '24px',
                        paddingBottom: '4px',
                        paddingLeft: '16px',
                      }}
                    >
                      <Link
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: 1,
                          letterSpacing: 0.32,
                          color: '#272735',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: '#535461',
                          },
                        }}
                        href="#"
                      >
                        Help center
                      </Link>
                    </List>
                  </Box>
                </Menu>
                <Button
                  id="basic-button"
                  aria-controls={openAbout ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAbout ? 'true' : undefined}
                  variant="text"
                  onClick={handleAboutMenu}
                  endIcon={
                    openAbout ? (
                      <KeyboardArrowUpRoundedIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  style={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#000000',
                    lineHeight: '1',
                    letterSpacing: '0.5px',
                  }}
                >
                  About
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElAbout}
                  open={openAbout}
                  onClose={handleCloseAboutMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '32px',
                      borderRadius: '16px',
                      backgroundColor: '#fbfcfd',
                      marginLeft: '-80px',
                    },
                  }}
                >
                  <Box
                    style={{ width: '372px', height: '192px', padding: '16px' }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{ padding: '16px', flexDirection: 'column' }}
                    >
                      {aboutMenu.map((data, index) => (
                        <List
                          key={index}
                          style={{
                            paddingTop: '8px',
                            paddingLeft: '16px',
                          }}
                        >
                          <Link
                            sx={{
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: 1,
                              letterSpacing: 0.32,
                              color: '#272735',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline',
                                color: '#535461',
                              },
                            }}
                            href="#"
                          >
                            {data}
                          </Link>
                        </List>
                      ))}
                    </Grid>
                  </Box>
                </Menu>
                <Button
                  variant="text"
                  style={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#000000',
                    lineHeight: '1',
                    letterSpacing: '0.5px',
                  }}
                >
                  Pricing
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={6}
              sm={isWeb1111 ? 4 : 6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {isWeb1111 ? (
                <>
                  <Button
                    variant="text"
                    onClick={handleLogin}
                    style={{
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 500,
                      color: '#000000',
                      lineHeight: '1',
                      letterSpacing: '0.5px',
                      marginRight: '8px',
                    }}
                  >
                    Log In
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSignup}
                    style={{
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 500,
                      color: '#FFFFFF',
                      lineHeight: '1',
                      letterSpacing: '0.5px',
                      marginRight: '8px',
                      backgroundColor: '#5266eb',
                      borderRadius: '24px',
                      padding: '12px 18px',
                    }}
                  >
                    Open Account
                  </Button>
                </>
              ) : (
                <IconButton
                  aria-label="play-pause"
                  size="large"
                  onClick={handleSidebar}
                  style={{ color: '272735' }}
                >
                  <MenuRoundedIcon fontSize="large" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div>
        <Drawer
          open={isSidebar}
          anchor={isMobile640 ? 'left' : 'right'}
          onClose={handleSidebar}
          sx={{
            '& .MuiDrawer-paper': {
              width: isMobile640 ? '100%' : '544px',
            },
          }}
        >
          {DrawerList}
        </Drawer>
      </div>
      <div>
        <Drawer
          open={isMainMenu}
          anchor={isMobile640 ? 'left' : 'right'}
          onClose={handleCloseMainMenu}
          sx={{
            '& .MuiDrawer-paper': {
              width: isMobile640 ? '100%' : '544px',
            },
          }}
        >
          {mainMenuDrawer}
        </Drawer>
      </div>
    </>
  );
}
export default Appbar;
