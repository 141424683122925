import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Landing from '../Landing/Landing';
import Login from '../Login/Login';
import Signup from '../Signup/Signup';

const RouterComponent = () => {
  return (
    <>
      <RouterRoutes>
        <Route path="/" Component={Landing} />
        <Route path="/login" Component={Login} />
        <Route path="/signup" Component={Signup} />
      </RouterRoutes>
    </>
  );
};

export default RouterComponent;
