import React from 'react';
import Box from '@mui/material/Box';
import accountImage from '../../images/account.avif';

const AccountSecurityTab = () => {
  return (
    <Box>
      <img
        src={accountImage}
        alt="accountImage"
        loading="lazy"
        style={{
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export default AccountSecurityTab;
