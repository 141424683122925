import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import '../../App.css';

const VideoPlayer = (props) => {
  const { videoSrc, posterSrc } = props;
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        preload="auto"
        poster={posterSrc}
        onPlay={handleVideoPlay}
        onPause={handleVideoPause}
        onEnded={handleVideoPause}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="play-button">
        <IconButton
          aria-label="play-pause"
          size="large"
          onClick={handlePlayPause}
          style={{ color: '272735' }}
        >
          {isPlaying ? (
            <PauseCircleOutlineRoundedIcon fontSize="large" />
          ) : (
            <PlayCircleOutlineRoundedIcon fontSize="large" />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default VideoPlayer;
