import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import trustImage from '../../images/trust.avif';
import safeImage from '../../images/safe.avif';
import wireImage from '../../images/wire.avif';
import { CButton } from '../Styles/styles';
import { useNavigate } from 'react-router-dom';

const SectionTwelve = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate('/signup');
  };

  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f1f7f3',
          marginTop: '0px',
          padding: '32px 16px',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={3}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#efeee9',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={trustImage}
                  alt="trustImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#2a2924',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                      marginBottom: '38px',
                    }}
                  >
                    Building trust as a finance leader
                  </Typography>
                </Box>
                <CButton variant="outlined">Read the Story</CButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#efeee9',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={safeImage}
                  alt="safeImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#2a2924',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                      marginBottom: '38px',
                    }}
                  >
                    Carolynn Levy, inventor of the SAFE
                  </Typography>
                </Box>
                <CButton variant="outlined">Read the Story</CButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#efeee9',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={wireImage}
                  alt="wireImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#2a2924',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                      marginBottom: '38px',
                    }}
                  >
                    Sending international wires through SWIFT
                  </Typography>
                </Box>
                <CButton variant="outlined">Read the Story</CButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ justifyContent: 'center', marginTop: '56px' }}
        >
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: '#28253b',
                fontSize: '36px',
                fontWeight: 450,
                lineHeight: '110%',
                letterSpacing: 0,
                textAlign: 'center',
              }}
            >
              Pricing that scales with you
            </Typography>
            <Typography
              style={{
                color: '#5a5548',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginTop: '8px',
                marginBottom: '24px',
                textAlign: 'center',
              }}
            >
              Plans start at $0/month and try our financial workflows for free
              until August 1st.
            </Typography>
            <CButton variant="outlined">View Pricing</CButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          backgroundColor: '#efeee9',
          marginTop: '0px',
          padding: '32px 16px',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '56px',
          }}
        >
          <Grid container spacing={2} style={{ justifyContent: 'center' }}>
            <Grid
              item
              xs={12}
              sm={4.5}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{
                  color: '#28253b',
                  fontSize: '36px',
                  fontWeight: 450,
                  lineHeight: '110%',
                  letterSpacing: 0,
                  marginBottom: '24px',
                  textAlign: 'center',
                }}
              >
                Expense enables over 200K startups of all sizes to operate at
                their highest level.
              </Typography>
              <Button
                variant="contained"
                onClick={handleSignup}
                style={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#FFFFFF',
                  lineHeight: '1',
                  letterSpacing: '0.5px',
                  backgroundColor: '#5266eb',
                  borderRadius: '24px',
                  padding: '12px 18px',
                  marginTop: '8px',
                }}
              >
                Open Account
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionTwelve;
