import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControl from '@mui/material/FormControl';
import dashboardImage from '../../images/dashboard.avif';
import mobileDashboardImage from '../../images/mobileDashboard.avif';

const SectionOne = () => {
  const navigate = useNavigate();
  const isMobile768 = useMediaQuery('(max-width:768px)');
  const isMobile640 = useMediaQuery('(max-width:640px)');

  const handleSignup = () => {
    navigate('/signup');
  };

  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: '#f5f4fd',
          marginTop: '0px',
          position: 'relative',
        }}
      >
        <Box>
          <img
            src={isMobile768 ? mobileDashboardImage : dashboardImage}
            alt="dashboardImage"
            loading="lazy"
            style={{
              width: '100%',
            }}
          />
        </Box>
        <Grid
          container
          style={{
            position: 'absolute',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 16px 0px 16px',
          }}
        >
          <Typography
            variant="h1"
            style={{
              color: '#28253b',
              fontSize: isMobile768 ? '42px' : '55px',
              fontWeight: 400,
              lineHeight: '110%',
              letterSpacing: isMobile768 ? 0.08 : 0,
            }}
          >
            Powerful banking.
          </Typography>
          <Typography
            variant="h1"
            style={{
              color: '#28253b',
              fontSize: isMobile768 ? '42px' : '55px',
              fontWeight: 400,
              lineHeight: '110%',
              letterSpacing: isMobile768 ? 0.08 : 0,
            }}
          >
            Simplified finances.
          </Typography>
          <Typography
            style={{
              color: '#554e7b',
              fontSize: isMobile768 ? '21px' : '24px',
              fontWeight: 450,
              lineHeight: '120%',
              letterSpacing: isMobile768 ? 0.16 : -0.08,
              marginTop: '8px',
            }}
          >
            Apply in 10 minutes for business banking that transforms how you
            operate.
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'baseline' }}>
            <FormControl
              variant="outlined"
              style={{
                paddingTop: '32px',
                minWidth: isMobile640 ? '342px' : '411px',
              }}
            >
              <OutlinedInput
                style={{
                  borderRadius: '28px',
                  height: isMobile640 ? '48px' : 'auto',
                }}
                endAdornment={
                  !isMobile640 && (
                    <InputAdornment position="end">
                      <Button
                      onClick={handleSignup}
                        variant="contained"
                        style={{
                          textTransform: 'none',
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#FFFFFF',
                          lineHeight: '1',
                          letterSpacing: '0.5px',
                          marginRight: '-8px',
                          backgroundColor: '#5266eb',
                          borderRadius: '24px',
                          padding: '12px 18px',
                        }}
                      >
                        Open Account
                      </Button>
                    </InputAdornment>
                  )
                }
                placeholder="Enter your email"
              />
            </FormControl>
            {!isMobile640 && (
              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#28253B',
                  lineHeight: '1',
                  letterSpacing: '0.5px',
                  marginRight: '-8px',
                  backgroundColor: '#7073931A',
                  borderRadius: '24px',
                  padding: '12px 18px',
                  borderColor: 'rgba(112,115,147,.22)',
                  marginTop: '8px',
                  marginLeft: '8px',
                }}
              >
                Contact Sales
              </Button>
            )}
          </Box>
          {isMobile640 && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '86%',
              }}
            >
              <Button
                variant="contained"
                onClick={handleSignup}
                style={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#FFFFFF',
                  lineHeight: '1',
                  letterSpacing: '0.5px',
                  backgroundColor: '#5266eb',
                  borderRadius: '24px',
                  padding: '12px 18px',
                  marginTop: '8px',
                }}
              >
                Open Account
              </Button>
              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#28253B',
                  lineHeight: '1',
                  letterSpacing: '0.5px',
                  backgroundColor: '#7073931A',
                  borderRadius: '24px',
                  padding: '12px 18px',
                  borderColor: 'rgba(112,115,147,.22)',
                  marginTop: '8px',
                }}
              >
                Contact Sales
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SectionOne;
