import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import sectionFourLeftImage from '../../images/sectionFourLeft.avif';
import masterCardImage from '../../images/masterCard.avif';
import { CButton } from '../Styles/styles';

const SectionFour = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f5f4fd',
          marginTop: '0px',
          padding: '32px 16px',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={4}
          style={{
            justifyContent: 'center',
          }}
        >
          <Grid item xs={12} sm={4.5}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#edecfb',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={sectionFourLeftImage}
                  alt="sectionFourLeftImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#28253b',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                    }}
                  >
                    Send and receive payments seamlessly
                  </Typography>
                  <Typography
                    style={{
                      color: '#554e7b',
                      fontSize: '18px',
                      fontWeight: 450,
                      lineHeight: '135%',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Set auto-transfer rules and send free USD wires
                  </Typography>
                </Box>

                <CButton variant="outlined">Checking & Savings</CButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                backgroundColor: '#edecfb',
                borderRadius: '16px',
                height: '100%',
                padding: '24px',
              }}
            >
              <Box style={{ paddingBottom: '24px' }}>
                <img
                  src={masterCardImage}
                  alt="masterCardImage"
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box style={{ paddingBottom: '24px' }}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#28253b',
                      fontSize: '24px',
                      fontWeight: 450,
                      lineHeight: '120%',
                      letterSpacing: '0.08px',
                    }}
                  >
                    Unlock credit cards earlier with industry-low deposit
                    minimums
                    <sup>2</sup>
                  </Typography>
                  <Typography
                    style={{
                      color: '#554e7b',
                      fontSize: '18px',
                      fontWeight: 450,
                      lineHeight: '135%',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Unlimited 1.5% cashback<sup>3</sup> on all spemd.
                  </Typography>
                </Box>
                <CButton variant="outlined">Explore Cards</CButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionFour;
