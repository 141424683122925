import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import bankingTaskPoster from '../../images/bankingTaskPoster.webp';
import bankingTaskVideo from '../../videos/bankingTask.mp4';
import VideoPlayer from '../Common/VideoPlayer';

const SectionThree = () => {
  return (
    <>
      <Grid
        style={{
          backgroundColor: '#f5f4fd',
          marginTop: '0px',
          padding: '32px 16px',
        }}
      >
        <Grid
          container
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant="h2"
            style={{
              color: '#28253b',
              fontSize: '36px',
              fontWeight: 450,
              lineHeight: '110%',
              letterSpacing: 0,
              textAlign: 'center',
            }}
          >
            Speed without compromise
          </Typography>
          <Typography
            style={{
              color: '#554e7b',
              fontSize: '24px',
              fontWeight: 450,
              lineHeight: '120%',
              letterSpacing: '0.08px',
              marginTop: '8px',
              marginBottom: '32px',
              textAlign: 'center',
            }}
          >
            Efficiently manage your banking and financial operations from a
            single dashboard.
          </Typography>
        </Grid>
        <Grid container spacing={4} style={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            sm={4.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#28253b',
                fontSize: '24px',
                fontWeight: 450,
                lineHeight: '120%',
                letterSpacing: '0.08px',
                marginBottom: '56px',
              }}
            >
              Complete any banking task in just a few clicks
            </Typography>
            <Divider style={{ marginBottom: '24px' }} />
            <Typography
              style={{
                color: '#554e7b',
                fontSize: '18px',
                fontWeight: 450,
                lineHeight: '135%',
                letterSpacing: '0.32px',
                marginBottom: '32px',
              }}
            >
              An operator’s dream. Mercury combines the speed, simplicity, and
              smarts that I need to get back to running my business. Search for
              data or actions, all at your fingertips.
            </Typography>
            <Typography
              style={{
                color: '#28253b',
                fontSize: '18px',
                fontWeight: 530,
                lineHeight: '135%',
                letterSpacing: '0.32px',
              }}
            >
              Lindsay Liu
            </Typography>
            <Typography
              style={{
                color: '#554e7b',
                fontSize: '18px',
                fontWeight: 450,
                lineHeight: '135%',
                letterSpacing: '0.32px',
              }}
            >
              CEO & co-founder, Super
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <VideoPlayer
              videoSrc={bankingTaskVideo}
              posterSrc={bankingTaskPoster}
            />
            {/* <Box>
              <img
                src={sectionThreeImage}
                alt="sectionThreeImage"
                loading="lazy"
                style={{
                  width: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionThree;
